.basics-content {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8) var(--hcr-spacing-2);
  background-color: var(--hcr-bg-primary-color);
}

.basics-api-secondary-nav {
  padding: var(--hcr-spacing-2) var(--hcr-spacing-2) var(--hcr-spacing-8) var(--hcr-spacing-4);
}

.basics-example-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.basics-example-postman-title {
  font-weight: var(--hcr-font-weight-bold);
}

.getting-started-container {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  flex-grow: 1;
}
