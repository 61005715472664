.apis-list {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8);
}

.apis-list-home-page {
  padding: 0 var(--hcr-spacing-4);
}

.api-skeleton {
  min-height: calc(12 * var(--hcr-spacing-2));
}
