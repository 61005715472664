.flows-list {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8);
}

.flows-list-home-page {
  padding: 0 var(--hcr-spacing-4);
}

.flow-skeleton {
  min-height: calc(8 * var(--hcr-spacing-2));
}
