.api-reference {
  background-color: var(--hcr-bg-primary-color);
  padding-top: var(--hcr-spacing-4);
}

.api-reference-header {
  padding-top: var(--hcr-spacing-2);
  padding-left: var(--hcr-spacing-2);
  padding-right: var(--hcr-spacing-2);
  position: fixed;
  top: var(--hcr-spacing-10);
  left: 0;
  z-index: 10;
  background-color: var(--hcr-bg-secondary-color);
  width: 100%;
}

.redoc {
  top: var(--hcr-spacing-5);
  width: 100vw;
}

div [role='search'] {
  background-color: var(--hcr-bg-secondary-color);
  position: sticky;
  z-index: 999;
  width: inherit;
  top: var(--hcr-spacing-3);
}

div [role='menu'] {
  padding-top: var(--hcr-spacing-3);
}

div [data-role='search:results'] {
  position: sticky;
  background-color: var(--hcr-grayscale-50-color);
  z-index: 999;
}

.menu-content {
  z-index: 2;
  padding-left: var(--hcr-spacing-1);
}

div [data-role='search:results'] li label {
  background-color: var(--hcr-grayscale-50-color);
}
