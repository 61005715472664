main {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8);
}

.columnHeader {
  font-weight: var(--hcr-font-weight-bold);
}

.callRateDisclaimer {
  display: block;
  width: 50%;
  margin-top: var(--hcr-spacing-2);
}
