.api-overview-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.api-overview-hero-section {
  padding: var(--hcr-spacing-4);
  background-color: var(--hcr-bg-primary-color);
  border-bottom: 1px solid var(--hcr-line-primary-color);
}

.api-overview-name-tag-group {
  display: flex;
  align-items: center;
  padding-bottom: var(--hcr-spacing-1);
}

.api-overview-content {
  padding: var(--hcr-spacing-2) var(--hcr-spacing-4) var(--hcr-spacing-8);
}

.api-overview-teams-tags {
  background-color: var(--hcr-box-bg-color);
}

.primary-background {
  background-color: var(--hcr-bg-primary-color);
  border-radius: var(--hcr-spacing-1);
}

.button-text {
  padding: 0 var(--hcr-spacing-6) 0 var(--hcr-spacing-1);
}

.subscription-switch {
  float: left;
}

.subscribe-button {
  height: var(--hcr-spacing-4);
}
