.flow-heading {
  padding-top: 0;
  padding-bottom: var(--hcr-spacing-1);
}

.flows-header {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-4);
  background-color: var(--hcr-bg-primary-color);
  border-bottom: 1px solid var(--hcr-line-primary-color);
}

.flows-header-home-page {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-2);
}
