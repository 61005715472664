.api {
  background-color: var(--hcr-bg-primary-color);
  border-radius: var(--hcr-spacing-1);
}

.api li {
  height: 110px;
}

.api-icon-container {
  border-right: 1px solid var(--hcr-line-primary-color);
  height: 109px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(-1 * var(--hcr-spacing-2)) 0;
}

.api-description-container {
  padding: 0 0 0 var(--hcr-spacing-2);
}

.api-description-container p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}

.api-description-container h3 {
  padding-bottom: 0;
}

.api-description-name-tag-group {
  display: inline flow;
  align-items: center;
  padding-bottom: var(--hcr-spacing-half);
}

.api-name {
  display: inline-block;
}

.api-teams-tags {
  background-color: var(--hcr-box-bg-color);
}
