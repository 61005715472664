.layout {
  background-color: var(--hcr-bg-secondary-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 1920px;
}

.layout-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--hcr-spacing-10);
}

.search-widget {
  width: 100%;
}
