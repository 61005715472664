.flow-heading {
  align-items: center;
}

.flow-details-teams-tags {
  background-color: var(--hcr-box-bg-color);
}

.heading-tags-container {
  display: flex;
  align-items: center;
}

.heading-tags-container h1 {
  display: inline-block;
  padding-bottom: 0;
}

.includes-api-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
