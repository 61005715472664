.changelogs__container {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex-grow: 1;
}

.change-type {
  font-weight: var(--hcr-font-weight-bold);
  display: inline-block;
}
