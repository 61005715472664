.SplitPane.vertical {
  position: static !important;
  display: grid;
}

.Pane.vertical.Pane2 {
  height: 100vh !important;
  overflow: auto;
}

.Pane.vertical.Pane2 {
  height: 100vh !important;
  overflow: auto;
}

.swagger-editor #ace-editor {
  height: 100% !important;
}

.editor-wrapper {
  height: 100% !important;
}

.editor-tool-bar {
  padding: var(--hcr-spacing-2) 0;
  display: flex;
  justify-content: flex-end;
}

.swagger-editor-col {
  border-top: 1px solid var(--hcr-line-primary-color);
}
