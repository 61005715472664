.footer {
  background-color: var(--hcr-bg-secondary-color);
  text-align: center;
  pointer-events: all;
  padding: 0 var(--hcr-spacing-4) var(--hcr-spacing-2) var(--hcr-spacing-4);
  flex-direction: column;
}

.footer__container {
  max-width: 1920px;
}

.footer__copyright {
  pointer-events: none;
  color: var(--hcr-content-secondary-color);
}

/* set spaces between footer items */
.footer__nav ul {
  justify-content: space-between;
}

.footer__switchers {
  top: -5px;
}
