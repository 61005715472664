.news-secondary-nav {
  padding: var(--hcr-spacing-2) var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-4);
}

.boldtext {
  font-weight: bolder;
}

.news-content {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8) var(--hcr-spacing-2);
  background-color: var(--hcr-bg-primary-color);
}
