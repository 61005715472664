.login {
  background: url('../../assets/img/loginBackground.avif') no-repeat center;
  background-size: cover;
}

.login-modal {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.login-box {
  border-color: var(--hcr-bg-secondary-color);
  border-radius: var(--hcr-spacing-1);
  background-color: var(--hcr-bg-primary-color);
  padding: var(--hcr-spacing-4) 0;
  min-width: var(--hcr-page-min-width);
}

.login-box-logo > svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
