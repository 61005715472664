.flow-secondary-nav {
  padding: var(--hcr-spacing-2) var(--hcr-spacing-2) var(--hcr-spacing-8) var(--hcr-spacing-4);
}

.flow-details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.flow-details .flow-grid {
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  justify-self: flex-end;
  align-items: stretch;
}

.flow-content {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-8) var(--hcr-spacing-2);
  background-color: var(--hcr-bg-primary-color);
}

.flow-description-info-box {
  font-family: var(--hcr-font-family-primary);
}

.flow-step {
  border-width: 2px;
}

.flow-step-names {
  border-width: 0px;
  background: none;
}

.flow-step-optional {
  border-style: dashed;
  border-width: 3px;
}

.flow-step-optional-tag {
  border: none;
  color: var(--hcr-secondary-content-color);
  font-weight: var(--hcr-font-weight-normal);
  background: none;
}

.flow-step-path {
  color: var(--hcr-primary-content-color);
}

.flow-step-method {
  background-color: var(--hcr-brand-primary-color);
  color: var(--hcr-grayscale-0-color);
  border: none;
}

.flow-step-description {
  color: var(--hcr-secondary-content-color);
}

.flow-step-info {
  display: inline-block;
}
