.hero-section {
  background-color: var(--hcr-bg-primary-color);
  border-bottom: 1px solid var(--hcr-line-primary-color);
}

.hero-section-logo {
  padding-left: var(--hcr-spacing-4);
  padding-top: var(--hcr-spacing-4);
  padding-bottom: var(--hcr-spacing-4);
}

.hero-section-description {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-4) var(--hcr-spacing-2);
}

.hero-section-description h1 {
  padding-bottom: var(--hcr-spacing-2);
}

.hero-section p {
  font-weight: var(--hcr-font-weight-bold);
}

.home-content {
  padding-bottom: var(--hcr-spacing-8);
}
