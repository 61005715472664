/*
Workarounds below are to set elements design on redoc that are not available
in the free version of redoc.
*/

/* Download API button */
a[download='openapi.json'] {
  background-image: var(--hcr-box-bg-color);
  border: 1px solid var(--hcr-button-primary-color);
  border-radius: var(--hcr-primary-border-radius);
  color: var(--hcr-content-primary-color);
}

a[download='openapi.json']:hover {
  background-image: var(--hcr-hover-layer-color);
}

a[download='openapi.json']:active {
  background-image: var(--hcr-pressed-layer-color);
}

/* =============== Response samples json =============== */
/* Used for the colon (:) symbols */
div [class='redoc-json'] code {
  color: var(--hcr-grayscale-30-color);
}

/* Used for the ellipsis (...) and othen punctuation symbols */
div [class='redoc-json'] code span[class*='ellipsis'],
div [class='redoc-json'] code span[class*='punctuation'] {
  color: var(--hcr-grayscale-30-color) !important;
}

/* Used for collapsers symbols (+ and -) */
div [class='redoc-json'] code button.collapser {
  color: var(--hcr-grayscale-10-color) !important;
}

/* Used for property names */
div [class='redoc-json'] code span.property {
  color: var(--hcr-grayscale-10-color) !important;
}

/* Used for string values */
div [class='redoc-json'] code span[class^='token string'] {
  color: var(--hcr-success-color) !important;
}

/* Used for numeric values */
div [class='redoc-json'] code span[class^='token number'] {
  color: var(--hcr-neutral-color) !important;
}

/* =============== Request samples =============== */
div[data-rttabs='true'] pre {
  color: var(--hcr-grayscale-30-color);
}

div[data-rttabs='true'] pre span[class*='punctuation'] {
  color: var(--hcr-bg-secondary-color);
}

div[data-rttabs='true'] pre span[class*='number'] {
  color: var(--hcr-success-color) !important;
}

div[data-rttabs='true'] pre span[class*='operator'] {
  color: var(--hcr-warning-color) !important;
}

div[data-rttabs='true'] pre span[class*='boolean'] {
  color: var(--hcr-grayscale-10-color);
}

div[data-rttabs='true'] pre span[class*='function'] {
  color: var(--hcr-grayscale-10-color);
}

div[data-rttabs='true'] pre span[class*='string'] {
  color: var(--hcr-brand-primary-color) !important;
}

div[data-rttabs='true'] pre span[class*='keyword'] {
  color: var(--hcr-neutral-color) !important;
}

/* Servers dropdown */
div[role='button'] > div {
  background-color: var(--hcr-box-bg-color);
}

/* Arrow button to select server */
button svg:nth-child(3) polygon {
  stroke: var(--hcr-input-border-color) !important;
  fill: var(--hcr-input-border-color) !important;
}

/* Rounding corners for all the buttons */
button {
  border-radius: var(--hcr-primary-border-radius) !important;
}

/* Copy, Expand all, Collapse all buttons*/
div > div > button:not([class]) {
  color: var(--hcr-bg-primary-color);
  border: 1px solid var(--hcr-button-primary-color) !important;
  border-radius: var(--hcr-primary-border-radius);
  background-color: var(--hcr-content-primary-color) !important;
}

/* Buttons inside the main center rendering of API */
td div button[class] {
  color: var(--hcr-grayscale-70-color);
  border: 1px solid var(--hcr-grayscale-50-color) !important;
  border-radius: var(--hcr-primary-border-radius);
}

/* Code section in the main center rendering of API */
tr > td > div {
  background-color: var(--hcr-bg-primary-color) !important;
}

/* Miscellaneous */
/* Labels in the main center rendering of API */
h5 {
  color: var(--hcr-content-secondary-color) !important;
}

h5 span {
  color: var(--hcr-content-primary-color) !important;
}

/* Left menu*/
ul[role='menu'] {
  font-size: 1.159em;
  padding-left: 3px !important;
}

ul[role='menu'] li a {
  margin: var(--hcr-spacing-half);
  padding: var(--hcr-spacing-half);
}

ul[role='menu'] li label {
  margin: var(--hcr-spacing-half) !important;
  padding: var(--hcr-spacing-half) !important;
  padding-left: var(--hcr-spacing-2) !important;
  color: var(--hcr-content-secondary-color) !important;
}

ul[role='menu'] li label.active {
  margin: var(--hcr-spacing-half) !important;
  padding: var(--hcr-spacing-half) !important;
  color: var(--hcr-content-primary-color) !important;
  background-color: var(--hcr-highlight-color);
}

ul[role='menu'] li label.active::before {
  padding: var(--hcr-spacing-half) !important;
  border-left: var(--hcr-spacing-half) solid var(--hcr-ui-primary-color);
  content: '';
}
