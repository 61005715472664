.flow {
  background-color: var(--hcr-bg-primary-color);
  border-radius: var(--hcr-spacing-1);
}

.flow li {
  height: 90px;
}

.flow-icon-container {
  height: 89px;
  border-right: 1px solid var(--hcr-line-primary-color);
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(-1 * var(--hcr-spacing-2)) 0;
}

.flow-description-container {
  padding: var(--hcr-spacing-1) 0 0 var(--hcr-spacing-2);
}

.flow-description-container p {
  font-size: 14px;
}

.flow-description-container h3 {
  padding-bottom: var(--hcr-spacing-half);
}
